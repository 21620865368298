import _ from 'lodash'

const getters = {
    getScreenLandscapeMode: state => state.gui.landscapeMode,
    getScreenPortraitMode: state => state.gui.portraitMode,
    getSmallScreenMode: state => state.gui.smallScreen,
    getUseDarkTheme: state => state.config.useDarkTheme,

    getThemeOverrides: state => {
        return _.merge( state.themes.both, ( state.config.useDarkTheme
            ? state.themes.dark : state.themes.light ) )
    }
}

export default getters;