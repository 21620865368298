const helper = {
    /**
     * Generates and returns a timestamp-string like "2021-5-14 19:2:48"
     *
     * @returns {string}
     */
    timestamp: function () {
        const today = new Date();
        const date = today.getFullYear() + '-' + ( today.getMonth() + 1 ) + '-' + today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date + ' ' + time;
        return dateTime;
    },

    /**
     * Generates and returns current time in milliseconds like "1621011750108"
     *
     * @returns {number}
     */
    milliseconds: function () {
        return Date.now();
    },

    /**
     * Generates a new route link for any local page
     *
     * @param vm        vue component object (this)
     * @param name      name of a valid route
     */
    routerLinkGet( vm, name ) {
        const lang = vm.$i18n.locale
        return { name: name, params: { lang } }
    },

    /**
     * Generates a new route link for any local page and sets it as new location to the router
     *
     * @param vm        vue component object (this)
     * @param name        name of a valid route
     */
    routerJumpTo( vm, name ) {
        vm.$router.push( this.routerLinkGet( vm, name ) )
    }
}

export default {
    install: ( app ) => {
        app.config.globalProperties.$helper = helper;
    }
}