/*
	DOKU: https://vue-i18n.intlify.dev/guide/#html
	REPO: https://github.com/intlify/vue-i18n-next
 */
import { createI18n } from 'vue-i18n'
import de from './de';
import en from './en';
import fr from './fr';
import gr from './gr';
import es from './es';

const messages = { de, en, fr, gr, es };
const fallbackLocale = 'en';
const localeNames = {
    en: 'English',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    gr: 'Ελληνικά'
}

const i18n = createI18n( {
    locale: fallbackLocale,
    fallbackLocale,
    globalInjection: true,
    compositionOnly: false,
    messages
} )

/** it's ugly but we can use this to translate strings inside js code
 * @param {string} key
 */
export const translate = ( key ) => {
    if ( !key )
        return ''

    return i18n.global.t( key )
};

export default i18n
export { localeNames }