const mutations = {
    SET_SCREEN_MODE: state => {
        state.gui.landscapeMode = window.innerWidth >= window.innerHeight
        state.gui.portraitMode = window.innerWidth < window.innerHeight
        state.gui.smallScreen = window.innerWidth < 990
    },

    SET_USE_DARK_THEME: ( state, { useDarkTheme } ) => {
        state.config.useDarkTheme = useDarkTheme
    }
}

export default mutations;