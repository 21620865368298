<template>
    <n-config-provider :theme="naiveTheme" :theme-overrides="themeOverrides">
        <n-notification-provider :max="5">
            <!-- M A I N   M E N U -->
            <top-main-menu />
            <div class="container main-content-container pt-1 pb-3">
                <!-- H E A D E R   S L I D E R -->
                <router-view name="HeaderSlider"></router-view>
                <!-- M A I N   C O N T E N T -->
                <router-view name="MainContent"></router-view>
            </div>
            <!-- F O O T E R -->
            <page-footer />
        </n-notification-provider>
    </n-config-provider>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { NConfigProvider, darkTheme } from 'naive-ui'
import TopMainMenu from "./components/navigation/TopMainMenu"
import PageFooter from "./components/footer/PageFooter"

export default {
    name: 'App',
    components: {
        NConfigProvider, TopMainMenu, PageFooter
    },
    data() {
        return {
            mediaQueryDarkTheme: window.matchMedia( "(prefers-color-scheme: dark)" )
        }
    },
    computed: {
        ...mapGetters( {
            useDarkTheme: 'getUseDarkTheme',
            naiveThemeOverrides: 'getThemeOverrides'
        } ),
        // https://www.naiveui.com/en-US/os-theme/docs/theme#element
        naiveTheme() {
            return this.useDarkTheme ? darkTheme : null
        },
        themeOverrides() {
            return this.naiveThemeOverrides
        }
    },
    methods: {
        ...mapMutations( { setScreenMode: 'SET_SCREEN_MODE' } ),
        setThemeModeToHtml() {
            if ( this.mediaQueryDarkTheme.matches ) {
                document.querySelector( 'html' )
                    .setAttribute( 'dark', true );
                this.$store.commit( 'SET_USE_DARK_THEME', { useDarkTheme: true } )
            } else {
                document.querySelector( 'html' )
                    .setAttribute( 'dark', false );
                this.$store.commit( 'SET_USE_DARK_THEME', { useDarkTheme: false } )
            }
        }
    },
    mounted() {
        this.setThemeModeToHtml()
        this.mediaQueryDarkTheme.addEventListener( 'change', this.setThemeModeToHtml )
        this.setScreenMode()
        window.addEventListener( 'resize', this.setScreenMode )
    }
}
</script>