import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

// DOKU: https://animate.style/
import 'animate.css/animate.min.css'

import router from './routes'
import store from "./store"
import helper from './_global_helper_func'
import i18n from "./translation"
import axios from 'axios'
import VueAxios from 'vue-axios'
import naive from "naive-ui"
import LazyLoad from "./plugins/LazyLoad"

import './css/globals.css'
import './css/theme-color.css'
import './css/animations.css'
import './css/fonts.css'
import './css/naiveui.css'

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp( App )

app.use( naive )
app.use( router )
app.use( store )
app.use( helper )
app.use( i18n )
app.use( VueAxios, axios )

app.directive( 'lazyload', LazyLoad )

app.mount( '#app' )