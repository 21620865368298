<template>
    <router-link
        :to="route"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
        <a
            :href="href"
            @click.prevent.stop="navigateTo(navigate, route)"
            :class="[
                'nav-item lvl' + level,
                isActive ? 'active' : '',
                isExactActive ? 'exact-active' : ''
            ]"
        >
            <span class="text">{{ label }}</span>
        </a>
    </router-link>
</template>

<script>
export default {
    emits: [ 'clickedOn' ],
    props: {
        title: { type: String, default: '' },
        toName: { type: String, default: 'home' },
        level: { type: Number, default: 1 },
        noClick: { type: Boolean, default: false }
    },
    computed: {
        query() {
            return this.$route.query
        },
        route() {
            return this.$helper.routerLinkGet( this, this.toName )
        },
        label() {
            const title = this.$t( this.title )
            let arr = title.split( " " )
            let newArr = []

            arr.forEach( str => {
                str = this.formatStringFirstUpper( str )

                if ( str.includes( "-" ) ) {
                    let innerArr = str.split( "-" )
                    let innerNewArr = []
                    innerArr.forEach( sub => {
                        innerNewArr.push( this.formatStringFirstUpper( sub ) )
                    } );
                    str = innerNewArr.join( "-" )
                }
                newArr.push( str )
            } );

            return newArr.join( " " )
        }
    },
    methods: {
        formatStringFirstUpper( str ) {
            return str.charAt( 0 ).toUpperCase() + str.slice( 1 ).toLowerCase()
        },
        navigateTo( navigateFunc, route ) {
            if ( !this.noClick ) {
                this.$emit( 'clickedOn', route )
                navigateFunc()
            }
        }
    }
}
</script>

<style scoped>
a {
    width: 100%;
    font-family: 'Teko', sans-serif;
    font-size: 24px;
}

a.lvl1 {
    position: relative;
    display: flow-root list-item;
}

a.lvl1, a.lvl2 {
    color: var(--top-nav-link) !important;
    transition: var(--nav-color-transition-property) var(--nav-color-transition-duration);
    transition-delay: var(--nav-color-transition-delay);
    transition-timing-function: var(--nav-color-transition-t-func);
}

a.lvl1:hover, a.lvl1.active:hover, a.lvl1.exact-active:hover,
a.lvl2:hover, a.lvl2.active:hover, a.lvl2.exact-active:hover {
    color: var(--page-text-blue-1) !important;
}

a.lvl1.active, a.lvl1.exact-active,
a.lvl2.active, a.lvl2.exact-active {
    color: var(--top-nav-btn-hover-bg) !important;
}

</style>