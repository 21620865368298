import axios from 'axios'

const actions = {
    sendEmail: ( context, apiData ) => {
        const url = '/api/contact_form.php'
        const config = { headers: { 'Content-Type': 'application/json' } }

        return axios.post( url, apiData, config )
            .then( function ( response ) {
                return response
            } )
            .catch( function ( error ) {
                return error
            } );
    }
};

export default actions;