/**
 * @see https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
 */

export default {
    updated: el => {
        function loadImage() {
            // const imageElement = Array.from( el.children ).find(
            //     el => el.nodeName === "img"
            // )

            const imageElement = el.querySelector( 'img' )

            if ( imageElement ) {
                imageElement.addEventListener( "load", () => {
                    setTimeout( () => el.classList.add( "loaded" ), 100 );
                } )
                imageElement.addEventListener( "error",
                    () => console.log( "LazyLoad could not find given URL: " + imageElement.dataset.url )
                )
                imageElement.src = imageElement.dataset.url;
            }
        }

        function handleIntersect( entries, observer ) {
            entries.forEach( entry => {
                if ( entry.isIntersecting ) {
                    loadImage();
                    observer.unobserve( el );
                }
            } );
        }

        function createObserver() {
            const options = {
                root: null,
                threshold: "0"
            };
            const observer = new IntersectionObserver( handleIntersect, options );
            observer.observe( el );
        }

        if ( window[ "IntersectionObserver" ] ) {
            createObserver();
        } else {
            loadImage();
        }
    }
};