<template>
    <div class="page-footer container-fluid py-4">
        <div class="footer-content container-sm">
            <div class="logo-line mb-3">
                <div
                    v-for="(item, index) in footerSocialLogos"
                    :key="index"
                >
                    <n-button
                        class="social-button"
                        type="primary"
                        ghost
                        @click="followUrlExternal($event, item.url)"
                    >
                        <n-icon class="me-2" :component="item.icon" />
                        {{ item.text }}
                    </n-button>
                </div>
            </div>
            <div class="copyright-line mb-4">
                <div class="copyright">© 1993-{{ new Date().getFullYear() }} Mykonos-Diving.com | W-Diving |
                    TheFreeman.de
                </div>
            </div>
            <div class="logo-line">
                <div class="logo-line-inner logos-3rd-party">
                    <div
                        v-for="(img, i) in footerLogos"
                        :key="i"
                        class="logo-image"
                        @click="followUrlExternal($event, img.url)"
                    >
                        <img class="img-fluid" :src="img.src" :alt="img.alt" :title="img.alt">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Viber as ViberIcon,
    Whatsapp as WhatsAppIcon,
    // Instagram as InstagramIcon,
    Facebook as FacebookIcon,
    MapMarkerAlt as MapMarkerIcon,
    PhoneAlt as PhoneIcon
} from '@vicons/fa'

export default {
    components: {},
    data() {
        return {}
    },
    computed: {
        footerLogos() {
            return [
                {
                    src: require( "../../assets/images/3rd_party_logos/padi_logo_color_576x200.png" ),
                    alt: 'Divers Alert Network: ' + this.$t( 'footer.logos.padi' ),
                    url: 'https://www.padi.com'
                },
                {
                    src: require( "../../assets/images/3rd_party_logos/dan_logo_652x200.png" ),
                    alt: 'Divers Alert Network: ' + this.$t( 'footer.logos.dan' ),
                    url: 'https://dan.org'
                },
                {
                    src: require( "../../assets/images/3rd_party_logos/iso_bvqi_429x200.png" ),
                    alt: 'Divers Alert Network: ' + this.$t( 'footer.logos.iso' ),
                    url: 'https://www.bureauveritas.de'
                },
                {
                    src: require( "../../assets/images/3rd_party_logos/scuba_pro_200x200.png" ),
                    alt: 'Divers Alert Network: ' + this.$t( 'footer.logos.spro' ),
                    url: 'https://www.padi.com'
                }
            ];
        },
        footerSocialLogos() {
            return [
                {
                    icon: WhatsAppIcon,
                    // Format: mit Ländervorwahl, ohne 00/ + / () oder Leerzeichen
                    url: 'https://api.whatsapp.com/send?phone=306945243928',
                    text: 'WhatsApp'
                },
                {
                    icon: ViberIcon,
                    // https://stackoverflow.com/questions/31339574/html-viber-link-to-specific-number
                    url: 'viber://chat?number=%2B306945243928',
                    text: 'Viber'
                },
                {
                    icon: PhoneIcon,
                    url: 'tel:+306945243928',
                    text: 'Phone'
                },
                {
                    icon: FacebookIcon,
                    url: 'https://www.facebook.com/diving.mykonos',
                    text: 'Facebook'
                },
                // TODO: Es ist noch keine Instagram-ID bekannt
                // {
                //     icon: InstagramIcon,
                //     url: 'https://www.instagram.com/waldemarfoit',
                //     text: 'Instagram'
                // },
                {
                    icon: MapMarkerIcon,
                    url: 'https://goo.gl/maps/zpEziULCLQoyRePs8',
                    text: this.$t( 'buttons.maps' )
                }
            ];
        }
    },
    methods: {
        followUrlExternal( event, url ) {
            if ( url.indexOf( 'skype' ) !== -1 )
                window.location = url;
            else
                window.open( url );
        }
    }
}
</script>

<style scoped>
.page-footer {
    background-color: var(--page-footer-bg);
}

.logo-line, .copyright-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.75rem;
}

.logo-line-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logos-3rd-party {
    width: 90vw;
    max-width: 600px;
}

.logos-3rd-party .logo-image img, .social-button {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.logos-3rd-party .logo-image:hover img, .social-button:hover {
    -webkit-filter: grayscale(1%); /* Safari 6.0 - 9.0 */
    filter: grayscale(1%);
}

.logo-image {
    cursor: pointer;
}

.logo-image:not(:last-child) {
    margin-right: 1rem;
}

.copyright-line {
    color: var(--bs-gray);
}

.copyright {
    text-align: center;
}

.social-button :deep(.n-icon) {
    transform: translateZ(0) translateY(-2px) !important;
}
</style>