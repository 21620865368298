import { createStore } from 'vuex'
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const store = createStore( {
        state() {
            return {
                config: {
                    useDarkTheme: false
                },
                gui: {
                    // This is written by App.vue, on a window resize event on checking window.innerHeight < 990 pixels
                    landscapeMode: true,
                    portraitMode: false
                },
                navigation: {
                    main: [
                        {
                            title: 'menu.home',
                            name: 'home'
                        },
                        {
                            title: 'menu.shop',
                            name: 'none',
                            hasSubs: [
                                { title: 'menu.shopSubs.location', name: 'location' },
                                { title: 'menu.shopSubs.equipment', name: 'equipment' }
                            ]
                        },
                        {
                            title: 'menu.activities',
                            name: 'activities',
                            hasSubs: [
                                { title: 'menu.activitiesSubs.discover', name: 'discover' },
                                { title: 'menu.activitiesSubs.boat', name: 'boat' },
                                // { title: 'menu.activitiesSubs.wreck', name: 'wreck' },
                                { title: 'menu.activitiesSubs.padi', name: 'padi' },
                                { title: 'menu.activitiesSubs.snorkle', name: 'snorkle' },
                                // { title: 'menu.activitiesSubs.private', name: 'private' },
                            ]
                        },
                        {
                            title: 'menu.prices',
                            name: 'prices'
                        },
                        // {
                        //     title: 'menu.gallery',
                        //     name: 'none'
                        // },
                        {
                            title: 'menu.contact',
                            name: 'contact'
                        },
                        {
                            title: 'menu.imprint',
                            name: 'imprint'
                        }
                    ]
                },
                /**
                 * @see https://www.naiveui.com/en-US/os-theme/docs/customize-theme
                 */
                themes: {
                    both: {
                        common: {
                            fontFamily: "'Roboto', 'Helvetica Neue', sans-serif",

                            primaryColor: "rgb(13, 110, 253)",
                            primaryColorHover: "rgb(117, 169, 244)",
                            primaryColorPressed: "rgb(71, 143, 248)",
                            primaryColorSuppl: "rgb(10 ,68, 155)",

                            errorColor: "#D04D30FF",
                            errorColorHover: "#de576d",
                            errorColorPressed: "#ab1f3f",
                            errorColorSuppl: "#de576d",

                            fontSize: "16px",
                            fontSizeMini: "14px",
                            fontSizeTiny: "14px",
                            fontSizeSmall: "15px",
                            fontSizeMedium: "16px",
                            fontSizeLarge: "17px",
                            fontSizeHuge: "18px",
                            lineHeight: "1.6",
                            heightMini: "16px",
                            heightTiny: "22px",
                            heightSmall: "28px",
                            heightMedium: "34px",
                            heightLarge: "40px",
                            heightHuge: "46px",
                            borderRadius: "3px"
                        },
                        Form: {
                            labelFontSizeTopMedium: "16px"
                        }
                    },
                    light: {
                        common: {
                            textColorBase: "rgb(1, 41, 102)",
                            textColor1: "rgba(1, 41, 102, 0.9)",
                            textColor2: "rgba(1, 41, 102, 0.82)",
                            textColor3: "rgba(1, 41, 102, 0.52)",
                            textColorDisabled: "rgba(1, 41, 102, 0.3)"
                        },
                        Dropdown: {
                            color: "rgba(170, 204, 255, 0.9)", // Hintergrund
                            optionTextColor: "rgba(4, 32, 74, 0.82)"
                        }
                    },
                    dark: {
                        common: {
                            textColorBase: "rgb(170, 204, 255)",
                            textColor1: "rgba(170, 204, 255, 0.9)",
                            textColor2: "rgba(170, 204, 255, 0.82)",
                            textColor3: "rgba(170, 204, 255, 0.52)",
                            textColorDisabled: "rgba(170, 204, 255, 0.38)"
                        },
                        Dropdown: {
                            color: "rgba(17,30,62,0.9)", // Hintergrund
                            optionTextColor: "var(--txt-nav-gray-5)"
                        },
                    }
                },
            }
        },
        actions,
        mutations,
        getters
    }
)

export default store;